import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import Accordion from "../components/Accordion/Accordion";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import About from "../components/Repeating/About";
import WhyUs from "../components/Repeating/WhyUs";
import Certifications from "../components/Repeating/Certifications";
import CTABackgroundColor from "../components/CTA/CTABackgroundColor";
import InstagramFeed from "../components/Repeating/InstagramFeed";

import akcStarPDF from "../downloads/BellaWoof_AKC-STAR-Puppy-Class_2024_Jul-Oct.pdf";

const Page = ({ data }) => {
  return (
    <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
      <SearchEngineOptimization
        title="Puppy Behavior Modification Classes San Diego | Bella Woof"
        description="Bella Woof offers top puppy behavior modification classes in San Diego. Behavior Modification is one of your dog’s most basic needs and we’re here to help! Learn more here."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <div className="container">
        <section className="pt-2 md:pt-20 pb-20 md:pb-28">
          <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-16 lg:gap-x-28 items-center">
            <div className="order-2 md:order-1">
              <h1>San Diego Puppy Training Classes</h1>
              <p>
                Training is one of your dog’s most basic needs and we’re here to help! Our
                professional puppy training classes will teach your furry friends to be well-behaved
                while they’re still young. You will learn invaluable information on how to care for,
                feed, socialize and train your puppy.
              </p>
              <p>
                Classes include off-leash socialization and basic on-leash obedience skills like
                Sit, Down, Wait/Stay, Leave It, Drop It, Leash Walking, Attention to Owner,
                Relaxation & Puppy Handling for Vets & Groomers. Instructions on positive puppy
                socialization interactions are given, as well as solutions to common puppy problems
                like mouthing, house-training & jumping.
              </p>
              <ButtonSolid
                href="tel:+1-619-237-0730"
                text="(619) 237-0730"
                className="gtm-phone-number"
              />
            </div>
            <div className="order-1 md:order-2">
              <Img
                fluid={data.intro.childImageSharp.fluid}
                alt="San Diego Puppy Behavior Modification Classes"
              />
            </div>
          </div>
        </section>
      </div>

      <Certifications className="mb-20 md:mb-32" />

      <section className="bg-primary_lighter pt-22 pb-20 md:py-28 mb-20 md:mb-28">
        <div className="container">
          <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-20 items-center mb-8 md:mb-6">
            <div className="order-2 md:order-1">
              <p className="text-primary text-xl font-extrabold mb-2">La Jolla</p>
              <h2>AKC Star Puppy Class</h2>
              <p>
                Per the American Veterinary Society of Animal Behavior (AVSAB), puppies can start
                class as early as 7-8 weeks of age. You will receive instructions on basic
                obedience, and positive puppy socialization, as well as solutions to common puppy
                problems like mouthing, house-training, and jumping. Build confidence and gain
                skills to help you communicate with your dog to reinforce your lifelong bond.
              </p>

              <ul>
                <li>
                  <a
                    className="flex items-center text-gray-400 hover:text-primary font-bold"
                    href={akcStarPDF}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2"
                    >
                      <path
                        d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                        stroke="#63BCB1"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7 10L12 15L17 10"
                        stroke="#63BCB1"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 15V3"
                        stroke="#63BCB1"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Download Class Info
                  </a>
                </li>
              </ul>
            </div>
            <div className="order-1 md:order-2">
              <Img
                fluid={data.AKCStar.childImageSharp.fluid}
                alt="Puppy & Intro Adult Dog Behavior Modification Package"
                className="rounded-3xl"
              />
            </div>
          </div>

          <p className="text-mobile8xl md:text-8xl font-heading leading-none font-bold text-primary text-opacity-30 mb-10 md:mb-12">
            Class Info
          </p>

          <div className="grid md:grid-cols-12 gap-y-8 md:gap-y-10">
            <div className="md:col-start-1 md:col-span-4">
              <p className="font-heading text-gray-900 font-bold text-lg mb-2">Where:</p>
              <ul className="mb-8">
                <li>Bella Woof</li>
                <li>7755 Girard Ave., La Jolla, CA 92037</li>
              </ul>

              {/* <p className="font-heading text-gray-900 font-bold text-lg mb-2">When:</p>
              <ul className="mb-8">
                <li>Saturdays at 9:30 am</li>
                <li>Sundays at 11 am</li>
                <li>Tuesdays at 6:30 pm</li>
              </ul> */}

              <p className="font-heading text-gray-900 font-bold text-lg mb-2">Sessions:</p>
              <ul className="mb-8">
                <li>
                  5 weekly classes, 1 hour each. 6 puppies max per class. Maximum 2 people per
                  puppy.
                </li>
              </ul>

              <p className="font-heading text-gray-900 font-bold text-lg mb-2">
                Dog Ages: (At start of class)
              </p>
              <ul className="mb-8">
                <li>
                  Small Breeds- 20 weeks max <br />
                  Medium & Large Breeds - 16 weeks max
                  <br />4 dogs max per class
                </li>
              </ul>

              <p className="font-heading text-gray-900 font-bold text-lg mb-2">Price:</p>
              <ul>
                <li>$249 total</li>
                <li>Puppy Combo Package: $419 total</li>
                <ul className="list-disc pl-4">
                  <li>Includes puppy class plus one 1-hour private lesson </li>
                  <li>Private lesson must take place before or during the 5-week puppy class</li>
                </ul>
              </ul>
            </div>
            <div className="md:col-end-13 md:col-span-8 md:border-l md:border-solid md:border-gray-500 md:border-opacity-30 md:pl-10">
              <p className="font-heading text-gray-900 font-bold text-lg mb-2 md:mb-6">
                Skills covered:
              </p>
              <ul className="styled-list primary mb-8 md:mb-12 grid md:grid-cols-2">
                <div>
                  <li>Sit</li>
                  <li>Down</li>
                  <li>Wait/Stay</li>
                  <li>Leave It</li>
                  <li>Drop It</li>
                </div>
                <div>
                  <li>Leash Walking</li>
                  <li>Attention to Owner</li>
                  <li>Relaxation</li>
                  <li>Puppy Handling for Vets and Groomers</li>
                </div>
              </ul>

              <Accordion className="shadow-primary" title="Prerequisites">
                <ul className="styled-list primary baseline">
                  <li>
                    Puppies must have at least one set of vaccinations given 7 days prior to class
                    start date.
                  </li>
                  <li>
                    To make sure each puppy gets quality teaching time, we only train 3-5 puppies
                    per class.
                  </li>
                </ul>
              </Accordion>
              <Accordion
                className="shadow-secondary"
                title="Classes & Behavior Modification Refund Policy"
              >
                <p>
                  <b>Classes & Behavior Modification Refund Policy</b> - All Classes & Training
                  Packages are non-refundable within 48 hrs of 1st scheduled class or training
                  session. All Classes & Training Packages are non-transferrable (dog or class). All
                  Training Packages must be completed within 3 months of purchase. No refunds or
                  partial refunds, when class or training packages have commenced.
                </p>

                <p>
                  <b>Behavior Modification Class Policy</b> - No make-up classes. No transfers. At
                  our discretion upon notification, a 15 minute make-up session may be available
                  prior to the next week's scheduled class.
                </p>
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="bg-secondary_light pt-22 pb-20 md:py-28 mb-20 md:mb-28">
            <div className="container">
               <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-20 items-center mb-16 md:mb-14">
                  <div className="order-2 md:order-1">
                     <div className="mb-10 md:mb-14">
                        <p className="text-secondary text-xl font-extrabold mb-2">SD Humane Society</p>
                        <h2>Puppy Preschool</h2>
                        <p>
                           Bella Woof is proud to partner with the San Diego Humane Society for Puppy Preschool! This training is designed to meet the
                           training and socialization needs of pups that are under 16 weeks old. Held at our San Diego Campus on Gaines Street, owners
                           and their pups learn to adapt to new people, dogs, environments, and experiences.
                        </p>
                     </div>

                     <p className="font-heading text-gray-900 font-bold text-lg mb-8">Register for this class</p>
                     <ul>
                        <li className="flex items-center mb-4">
                           <a className="gtm-phone-number text-gray-400 hover:text-secondary font-bold" href="tel:+1-619-237-0730">
                              <i className="far fa-phone-alt mr-1 text-3xl text-secondary"></i> (619) 237-0730
                           </a>
                        </li>
                        <li className="flex items-center">
                           <a
                              className="text-gray-400 hover:text-secondary font-bold"
                              href="https://www.sdhumane.org/programs/behavior-center"
                              target="_blank"
                              rel="noreferrer"
                           >
                              <i className="far fa-globe mr-1 text-3xl text-secondary"></i> www.sdhumane.org/programs/behavior-center
                           </a>
                        </li>
                     </ul>
                  </div>
                  <div className="order-1 md:order-2">
                     <Img fluid={data.preschool.childImageSharp.fluid} alt="Puppy & Intro Adult Dog Training Package" className="rounded-3xl" />
                  </div>
               </div>

               <p className="text-mobile8xl md:text-8xl leading-none font-heading font-bold text-secondary text-opacity-30 mb-10 md:mb-12">
                  Class Info
               </p>

               <div className="grid md:grid-cols-12 gap-y-8 md:gap-y-10">
                  <div className="md:col-start-1 md:col-span-4">
                     <p className="font-heading text-gray-900 font-bold text-lg mb-2">Where:</p>
                     <ul className="mb-8">
                        <li>San Diego Humane Society</li>
                        <li>5500 Gaines St., San Diego, CA 92110</li>
                     </ul>

                     <p className="font-heading text-gray-900 font-bold text-lg mb-2">When:</p>
                     <ul className="mb-8">
                        <li>Wednesday evenings & Saturdays</li>
                     </ul>

                     <p className="font-heading text-gray-900 font-bold text-lg mb-2">Sessions:</p>
                     <ul className="mb-8">
                        <li>6 weekly classes, 1 hour each.</li>
                        <li>First class is an orientation for owners only.</li>
                     </ul>

                     <p className="font-heading text-gray-900 font-bold text-lg mb-2">Dog Ages:</p>
                     <ul className="mb-8">
                        <li>16 weeks or under</li>
                     </ul>

                     <p className="font-heading text-gray-900 font-bold text-lg mb-2">Price:</p>
                     <ul>
                        <li>$170 total </li>
                     </ul>
                  </div>
                  <div className="md:col-end-13 md:col-span-8 md:border-l md:border-solid md:border-gray-500 md:border-opacity-30 md:pl-10">
                     <p className="font-heading text-gray-900 font-bold text-lg mb-2 md:mb-6">Skills covered:</p>
                     <ul className="styled-list secondary mb-8 md:mb-12 grid md:grid-cols-2">
                        <div>
                           <li>Basic puppy care</li>
                           <li>House-training</li>
                           <li>Off-leash socialization</li>
                           <li>Appropriate greetings</li>
                        </div>
                        <div>
                           <li>Basic skills, including sit, down, wait, come</li>
                           <li>Leash walking</li>
                           <li>Drop it</li>
                        </div>
                     </ul>

                     <Accordion className="shadow-secondary" title="Prerequisites">
                        <ul className="styled-list secondary baseline">
                           <li>Puppies must be under 16 weeks old.</li>
                           <li>
                              If your puppy will be older than 16 weeks of age when the class starts, please register for Level 1: Marvelous Manners.
                           </li>
                           <li>Puppies must have at least one set of vaccinations given 7 days prior to class start date.</li>
                        </ul>
                     </Accordion>
                     <Accordion className="shadow-secondary" title="Cancellation & No-Show Policy">
                        <p>
                           In deference to other students, those who arrive 10 minutes late to class will not be admitted and will be marked as
                           absent. No class make ups will be given for students who are tardy.
                        </p>
                     </Accordion>
                     <Accordion className="shadow-secondary" title="Refund Policy">
                        <ul className="styled-list secondary baseline mb-6">
                           <li>
                              Classes must be paid for in full at least 72 hours prior to the scheduled start date, including online registrations and
                              manual registrations.
                           </li>
                           <li>If space is still available, class registrations will be accepted until the start of the scheduled class.</li>
                           <li>Refunds and cancellations are offered until 72 hours prior to the class’ scheduled start date.</li>
                           <li>
                              Students must request a cancellation no later than 72 hours prior to the scheduled class start date to receive a refund.
                           </li>
                           <li>Refunds will be processed within one week of student's request for refund/cancellation.</li>
                           <li>No drop-ins, class transfers, or pro-rated class fees.</li>
                        </ul>
                        <p>
                           Classes can be cancelled and/or postponed with no notice. The Humane Society will do their best to alert you if classes are
                           cancelled or rescheduled, so you may adjust your schedule accordingly. You will receive the full number of sessions, but
                           the class end date may be later than anticipated.
                        </p>
                     </Accordion>
                  </div>
               </div>
            </div>
         </section> */}

      <WhyUs className="mb-20 md:mb-32" />

      <About className="mb-20 md:mb-32" />

      <TestimonialBackgroundImage />

      <CTABackgroundColor>
        <h2>Train with us!</h2>
        <p>
          Whether you have a puppy or an adult furry friend, we have classes in different locations
          to suit their needs. Get in touch to book behavior modification today!
        </p>
      </CTABackgroundColor>

      <InstagramFeed />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(relativePath: { eq: "open-graph/facebook/Puppy Training_FB.jpg" }) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Puppy Training_Twitter.jpg" }
    ) {
      publicURL
    }
    intro: file(relativePath: { eq: "training/puppy-training/1.0-Puppy-Training-Introv2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1256, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    AKCStar: file(relativePath: { eq: "training/puppy-training/AKC Star Puppy Class.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1096, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    preschool: file(relativePath: { eq: "training/puppy-training/Puppy Preschool.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1096, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Page;
